import React from 'react';
import travelJournalHero from 'assets/travel-journal-hero-home.webp';
import styles from './Section.module.scss';

import { SectionProps } from './types';

import { Body, Heading } from 'components/text';
import Button from 'components/Button/BaseButton';
import Image from 'next/image';
import Link from 'next/link';
import { navigationPaths } from 'data/constants';

const Section: React.FC<SectionProps> = ({ articles }) => {
  return (
    <div className={styles.container}>
      <Link href={navigationPaths.TRAVEL_JOURNAL_ARTICLES}>
        <div className={styles.hero}>
          <Image
            src={travelJournalHero}
            alt="Travel Journal"
            layout="fill"
            objectFit="cover"
          />
          <div>
            <div>
              <Heading color="white" as="h4">
                Travel Journal
              </Heading>
              <Body color="white" weight="demiBold">
                Destination guides, hotel reviews, insider tips and travel blogs
                through a luxury lens.
              </Body>
            </div>

            <Button>Travel Journal</Button>
          </div>
        </div>
      </Link>

      {articles.map(article => {
        return (
          <Link
            key={article.id}
            href={navigationPaths.TRAVEL_JOURNAL_ARTICLE.replace(
              ':slug',
              article.slug
            )}
          >
            <div className={styles.article} key={article.id}>
              <Image
                src={article.header_image}
                alt={article.title}
                layout="fill"
                objectFit="cover"
              />

              <div>
                <Body color="white" weight="demiBold">
                  {article.title}
                </Body>
              </div>

              <span className={styles.overlay} />
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Section;
